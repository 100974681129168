<template>
  <div>
    <h1
      class="display-2 text-center mt-5"
      style="font-weight: 100"
    >
      <span
        class="greeting"
        v-html="greeting"
      ></span>&nbsp;
      <v-btn
        style="position:absolute;"
        icon
        @click="getGreeting()"
      >
        <v-icon color="grey">
          shuffle
        </v-icon>
      </v-btn>
    </h1>
    <v-layout
      row
      wrap
      justify-center
    >
      <v-flex
        v-for="sidebarItem in filteredSidebar"
        :key="sidebarItem.name"
        xs12
        md6
        lg3
        xl2
        @click="reroute(sidebarItem)"
      >
        <v-card class="menu-card pointer fill-height pa-3">
          <v-layout align-center>
            <v-flex shrink>
              <v-icon
                x-large
                class=" menu-card-icon"
                :class="sidebarItem.color + '--text'"
              >
                {{ sidebarItem.icon }}
              </v-icon>
            </v-flex>
            <span
              class="title"
              :class="sidebarItem.color + '--text'"
            >{{ sidebarItem.text }}</span>
          </v-layout>
          <v-divider></v-divider>
          <v-layout>
            <v-flex>
              <p class="mb-0">
                {{ sidebarItem.description }}
              </p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import sidebarConfig from '../../shared/config/sidebar'

export default {
  name: 'Dashboard',
  data() {
    return {
      sidebar: sidebarConfig,
      greeting: null,
    }
  },
  computed: {
    ...mapState(['mercuryAppInstances', 'user']),
    filteredSidebar() {
      return this.sidebar.filter(el => (!el.hideOnDefault || this.mercuryAppInstances.selected !== 'DEFAULT'))
    },
  },
  created() {
    this.getGreeting()
  },
  methods: {
    reroute(item) {
      this.$router.push((item.items ? item.items[0].link : item.link))
    },
    getGreeting() {
      this.greeting = 'Hallo'
    },
  },
}
</script>

<style lang="scss">
  .greeting {
    a {
      text-decoration: none;
      border-bottom: 2px solid #01579b;
      padding-bottom: 0px;
    }
  }

  .menu-card {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

  }

  .menu-card:hover {

    .menu-card-icon {
      transform: rotate(-4deg) scale(1.2);
    }

    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0px rgba(0, 0, 0, 0.14), 0 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .menu-card-icon {
  }
</style>
